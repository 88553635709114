import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { Center, Html, Text, useAnimations, useGLTF } from "@react-three/drei";
import * as THREE from "three";
import { MeshStandardMaterial } from "three";
import { forwardRef } from "react";
import { useControls } from "leva";
import _ from "lodash";
import { DEG2RAD, degToRad } from "three/src/math/MathUtils.js";
import { clone } from "three/examples/jsm/utils/SkeletonUtils.js";
import useGLTF_cloned from "../loaders/useGLTF_cloned";
import { getv, iso, jstr, nils } from "../utils/utils.js";
import { useModelsStore } from "./ModelsStore.js";
import { useInterfaceContext, useInterfaceControls } from "./Interface.js";
import { useFrame, useThree } from "@react-three/fiber";
import { useRaceRunContext } from "./RaceRunner.js";
import { Vector3 } from "three";
import { Trail } from "./Trails.js";
import { CarTrail } from "./trails/CarTrail";

export default forwardRef(function Car(
  { highlight_bike = false, bike, curranim, modelcentered = false, ...props },
  ref,
) {
  const group = useRef();
  const models_store = useModelsStore();

  const incon = useInterfaceControls();

  const rruncon = useRaceRunContext();
  const { runmode, t } = rruncon;

  const model = models_store.get_model("car");
  const clonedmodel = useGLTF_cloned(model);
  const { nodes, materials, animations = [] } = clonedmodel;

  const { hex_code, hid, name } = bike;
  const neon = useMemo(() => {
    return `#${hex_code}`;
  }, [hex_code]);

  const prevanim = useRef(null);

  const anims = useAnimations(animations, group);
  const actions = anims.actions;
  const update_anim = (
    curr,
    crossfadetime = 0.5,
    crossfade = true,
    fromtime = 0,
  ) => {
    let prev = prevanim.current;
    let action = actions[curr];
    if (action) {
      actions[curr].time = fromtime;
      if (crossfade && prev && actions[prev]) {
        actions[prev].crossFadeTo(actions[curr], crossfadetime);
        actions[curr].play();
      } else {
        action.play();
      }
      prevanim.current = curr;
    }
  };
  const update_anim2 = (curr, fromtime = 0) => {
    let prev = prevanim.current;
    let action = actions[curr];
    if (action) {
      action.time = fromtime;
      action.play();
      prevanim.current = curr;
    }
  };
  useEffect(() => {
    if (group.current) {
      group.current.update_anim = update_anim;
      group.current.update_anim2 = update_anim2;
      group.current.prevanim = prevanim;
    }
  }, [group]);

  React.useImperativeHandle(ref, () => group.current);

  const trailShow =
    ["running", "replay"].includes(runmode) && t > 0 ? true : false;

  useEffect(() => {
    if (!_.isEmpty(anims?.names) && trailShow) {
      setTimeout(() => {
        update_anim2("Scene");
      }, Math.random() * 2000);
    }
  }, [jstr(anims?.names), trailShow]);

  const showname = useRef(false);

  if (_.isEmpty(model))
    return (
      <>
        <mesh>
          <boxGeometry args={[0.2, 0.2, 0.2]} />
        </mesh>
      </>
    );

  return (
    <group
      // ref={ref}
      ref={group}
      {...props}
      dispose={null}
      onClick={() => {
        incon.set_showhstats(hid);
      }}
      // onPointerEnter={() => {
      //   showname.current = true;
      // }}
      // onPointerLeave={() => {
      //   showname.current = false;
      // }}
    >
      {showname.current == true ? (
        <Text rotation-y={degToRad(90)} position-y={0.8} fontSize={0.18}>
          {bike.name}
        </Text>
      ) : (
        <></>
      )}

      <pointLight
        color={neon}
        position={[-0.8, 0.3, 0]}
        intensity={5}
        distance={1.2}
        decay={1}
      />

      <group scale={1} {...(modelcentered ? {} : { "position-x": -0.8 })}>
        <primitive object={clonedmodel.scene} />
        {(trailShow || true) && (
          <group>
            <CarTrail />
          </group>
        )}
      </group>
    </group>
  );
});
