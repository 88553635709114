import {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
// import { metrics } from "../track/track.js";
import TronBike from "./TronBike.js";
import { get_race_rtstatus, useRace } from "./Race.js";
import {
  bez_get_controlpoints,
  bez_y_from_x,
  getv,
  jstr,
  nano,
  nils,
} from "../utils/utils.js";
import * as THREE from "three";
import _ from "lodash";
import { useNowContext } from "../App.js";
import { useFrame, useThree } from "@react-three/fiber";
import { useCameraControls } from "./CameraControls.js";
import { p_vec3 } from "../utils/vectorutils.js";
import { useMouseControls } from "./MouseControls.js";
import { useControls } from "leva";
import { create as createZustand } from "zustand";
import {
  pause_audio,
  start_audio,
  stop_all_audio,
  stop_audio,
} from "./Audio.js";
import { InterfaceWrapper } from "./Interface.js";
import { metrics, sx } from "../track/metrics.js";
import Car from "./Car.js";

const metk = "car";
const bint_w = metrics?.[metk].between;
const b_w = metrics?.[metk].width;
const b_size = metrics?.[metk].scale;

const h_lerpfac = 1;
const c_lerpfac = 1;
const cam_lerpfac = 0.5;

const animcodes = {
  idle: "idle",
  running: "running",
  sleep: "idle",
};

export const RaceRunContext = createContext({});
export const useRaceRunContext = () => useContext(RaceRunContext);

const get_h_runmp = (t, r, h, href) => {
  let hid = h.hid;
  if (!href || t == null)
    return {
      hid,
      covered: 0,
      posx: 0,
    };

  const dist = getv(r, "cb") * 100;
  let elap = t;
  const bez = _.find(h.bezs, (e, idx) => {
    if (idx + 1 == h.bezs.length) return true;
    return _.inRange(elap, e.xs[0], e.xs[1]);
  });
  const ps = bez_get_controlpoints(bez.bezstr);
  let covered = bez_y_from_x(elap, ps, bez.xs, bez.ys);
  covered = Math.min(covered, dist + 20);
  let posx = covered * sx;
  covered = Math.min(covered, dist);
  let hpos = new THREE.Vector3().copy(href.position);
  hpos.x = posx;
  let o = { hid, covered, posx };
  return o;
};

export const RaceRunWrapper = (props) => {
  const [init, set_init] = useState(false);

  const t_ref = useRef(null);
  const racect = useRace();
  const { race, racerunhs } = racect;
  const { now } = useNowContext();
  const camct = useCameraControls();

  const hsin = getv(racect, "race.hs_in") ?? 0;
  const hs_refs = useRef([]);

  const [runmode, set_runmode] = useState("stopped");
  const [speed, set_speed] = useState(1);

  useEffect(() => {
    if (hsin == 0) return;
    if (hs_refs.current.length == hsin) return;
    hs_refs.current = [...Array(hsin)].map((e) => null);
  }, [hsin]);

  useEffect(() => {
    if (racect.loaded == true && t_ref.current === null) {
      setTimeout(() => {
        console.log("init timeout");
      }, 5000);
    }
  }, [racect.loaded]);

  useEffect(() => {
    if (camct.loaded == true) {
      let cam = camct.orbit.object;
      cam.position.set(5.7, 3.25, 3.06);
      // console.log("init cam position", cam.position);
    }
  }, [camct.loaded]);

  const prevrtstatus = useRef(null);
  const [rtstatus, set_rtstatus] = useState(null);
  const [currrtstatus, rem_st, rem_ed, r_st] = useMemo(() => {
    if (racect.loaded == false) return [null, null, null, null];
    let e = get_race_rtstatus(race, now);
    e = [...e, nano(race.start_time)];
    prevrtstatus.current = rtstatus;
    return e;
  }, [now, jstr(race), racect.loaded]);

  useEffect(() => {
    // console.log("setting rtstatus", prevrtstatus.current, currrtstatus);
    if (nils(rtstatus)) {
      set_rtstatus(currrtstatus);
      return;
    }

    if (currrtstatus == rtstatus) return;
    if (prevrtstatus.current == "live") return;
    set_rtstatus(currrtstatus);
  }, [currrtstatus]);

  const [mintime, maxtime] = useMemo(() => {
    if (["live", "finished"].includes(rtstatus)) {
      let times = _.map(racerunhs, "time");
      return [_.min(times), _.max(times)];
    }
    return [null, null];
  }, [rtstatus, jstr(race), jstr(racerunhs)]);

  const init_effect = () => {
    // if (!nils(t_ref.current)) return;
    if (rtstatus == "finished") {
      if (!nils(maxtime)) {
        let m = 0;
        if (m == 0) {
          t_ref.current = -10;
          set_runmode("running");
        } else if (m == 1) {
          t_ref.current = maxtime - 10;
          set_runmode("running");
        } else if (m == 2) {
          t_ref.current = maxtime - 2;
          set_runmode("running");
        } else if (m == 3) {
          t_ref.current = 0;
          set_runmode("stopped");
        }
      }
    }
    if (rtstatus == "live") {
      t_ref.current = (now - r_st) / 1000;
      set_runmode("running");
    }
    if (rtstatus == "scheduled") {
      t_ref.current = -rem_st;
      set_runmode("running");
    }
    if (rtstatus == "open") {
      t_ref.current = null;
      set_runmode("stopped");
    }
  };

  // running
  useEffect(() => {
    if (init == false) return;
    init_effect();
  }, [rtstatus, init]);

  const rtgt = useMemo(() => {
    if (nils(t_ref.current)) return { 0: false, "-8": false };
    return {
      0: t_ref.current > 0,
      "-8": t_ref.current > -8,
    };
  }, [now]);

  const get_play_audio_id = ({ rtgt, runmode }) => {
    if (rtgt["-8"] == true) {
      if (runmode == "running") {
        if (rtgt["0"]) {
          return ["racing", true];
        } else {
          return ["starting", false];
        }
      } else if (runmode == "replay") {
        return ["racing_slow", true];
      } else if (runmode == "podium") {
        return ["podium", true];
      }
    } else {
      return [null, false];
    }
  };

  const prevaud = useRef(null);
  useEffect(() => {
    if (runmode == "stopped") {
      stop_all_audio();
    }
    let newaud = get_play_audio_id({ rtgt, runmode });
    if (jstr(prevaud.current) !== jstr(newaud) && newaud && !nils(newaud[0])) {
      let [audid, loop] = newaud;
      start_audio(audid, loop);
      prevaud.current = newaud;
    }
  }, [rtgt, runmode]);

  const repoffs = 1.5;
  const repspeed = 0.2;

  // replay
  useEffect(() => {
    if (runmode !== "running") return;
    let t = t_ref.current;
    if (nils(t) || nils(mintime) || nils(maxtime)) return;
    if (t > maxtime + repoffs) {
      const dist = getv(race, "cb") * 100;

      set_runmode("replay");
      set_speed(repspeed);
      t_ref.current = mintime - repoffs;
      let orbit = camct.orbit;

      setTimeout(() => {
        orbit.object.position.set(dist * sx, 4.5, 9);
        orbit.target.set(dist * sx, 0, 0);
      }, 300);

      let replayduration = maxtime + repoffs - (mintime - repoffs);

      setTimeout(
        () => {
          set_runmode("podium");
        },
        replayduration * (1 / repspeed) * 1e3,
      );
    }
  }, [t_ref.current, mintime, maxtime, runmode]);

  // podium
  useEffect(() => {
    if (runmode !== "podium") return;
    let orbit = camct.orbit;
    setTimeout(() => {
      // let cam = [19.41, 6.37, 6.85];
      // let tar = [-0.73, 3.54, 0.19];

      let cam = [22.85, 5.91, 0.92];
      let tar = [-0.73, 3.54, 0.19];

      orbit.object.position.set(...cam);
      orbit.target.set(...tar);
    }, 1 * 1e3);
  }, [runmode]);

  useEffect(() => {
    // console.log("status", prevrtstatus.current, currrtstatus, {
    // status: rtstatus,
    // t: t_ref.current,
    // });
  }, [currrtstatus, prevrtstatus, rtstatus, t_ref]);

  const rruncon = {
    t_ref,
    t: t_ref.current,
    hs_refs,
    runmode,
    set_runmode,
    speed,

    init,
    set_init,
  };

  return (
    <RaceRunContext.Provider value={rruncon}>
      {props.children}
    </RaceRunContext.Provider>
  );
};

export const useRaceRunnerControls = createZustand((set) => ({
  loaded: false,
  update: (upd = {}) => {
    return set((state) => ({ ...state, ...upd }));
  },
}));

export const RaceRunner = () => {
  const r3fs = useThree();
  const raceruncon = useRaceRunContext();
  const racect = useRace();
  const racerunningct = useRaceRunnerControls();
  const camct = useCameraControls();
  const mousect = useMouseControls();

  const speed = 1;
  const { t, t_ref, hs_refs, runmode } = raceruncon;
  const { race, hsob, racerunhs } = racect;

  const hsin = getv(racect, "race.hids")?.length ?? 0;
  const dist = getv(racect, "race.cb") * 100 ?? 0;

  const [smoothedCameraPosition] = useState(() => new THREE.Vector3());
  const [smoothedCameraTarget] = useState(() => new THREE.Vector3());
  const [smoothedHsPositions] = useState(() =>
    [...Array(hsin)].map(() => {
      return null;
    }),
  );

  useFrame((r3f, delta) => {
    if (!["running", "replay", "running_sch"].includes(runmode)) return;
    const hsrunninginfo = [];
    // console.log("t", t_ref.current);
    if (t_ref.current === null) return;
    if (camct.loaded == false) return;

    t_ref.current = t_ref.current + delta * speed;
    // t_ref.current = 0;

    let t = t_ref.current;

    // if (t === 0) return;
    // if (t <= 0) return;
    let max_cov = 0;
    const rcam = r3fs.camera;
    const orbit = camct?.orbit;
    const camera = camct?.camera;
    const tarobj = camct?.tarobj;

    if (t > 0) {
      let hids = getv(race, "hids");
      let i = 0;
      let covs = [];
      for (let hid of hids) {
        let href = hs_refs.current[i];
        if (href) {
          let hrunmp = racerunhs[hid];
          let hrp = get_h_runmp(t, race, hrunmp, href);

          let hpos = new THREE.Vector3().copy(href.position);
          hpos.x = hrp.posx;

          let backing = 2;
          // max_cov = Math.max(max_cov - backing, hrp.covered);
          covs.push(hrp.covered);

          if (nils(smoothedHsPositions[i])) {
            smoothedHsPositions[i] = hpos;
          }
          let smoothhpos = smoothedHsPositions[i];
          smoothhpos.lerp(hpos, h_lerpfac);
          href.position.copy(smoothhpos);

          hsrunninginfo.push(hrp);
        }
        ++i;
      }
      max_cov = Math.max(...covs);
      max_cov = Math.min(max_cov, dist + 20);

      if (runmode == "running") {
        let maxposx = max_cov * sx;
        let diff = maxposx - tarobj.position.x;
        // console.log({ diff });

        let t_v = new THREE.Vector3().copy(tarobj.position);
        t_v.x = maxposx;
        smoothedCameraTarget.lerp(t_v, c_lerpfac);
        tarobj.position.copy(smoothedCameraTarget);

        rcam.position.x += diff;

        orbit.target.copy(tarobj.position);
        orbit.update();
      }

      const hsrunninginfo_ob = _.keyBy(hsrunninginfo, "hid");
      racerunningct.update({ t, hsrunninginfo_ob, max_cov });
    }
  });

  return (
    <>
      <>
        {[...Array(hsin)].map((e, i) => {
          let hid = race.hids[i];
          let h = hsob[hid];
          if (nils(h)) return null;
          let gate = i + 1;
          let posz = -(gate - hsin / 2 - 0.5) * (b_w + bint_w);
          return (
            <Car
              ref={(e) => {
                return (hs_refs.current[i] = e);
              }}
              highlight_bike={race.is_arcade == true && h.is_user == true}
              bike={h}
              {...{
                key: i,
                "position-x": 0,
                "position-y": 0,
                "position-z": posz,
                scale: b_size,
                index: i,
              }}
            />
          );
        })}
      </>
    </>
  );
};
